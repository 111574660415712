import React, {useReducer, PropsWithChildren} from 'react';
import {reducer} from 'lib';
import type { Episode, EpisodeMeta } from 'types';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import XMLParser from 'react-xml-parser';
import { cdnImage } from 'components';
import {Parser} from 'html-to-react';
import { episodeMeta } from 'data';
import { useRefs } from 'hooks';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
const parser = Parser();
 
const DEFAULT_PROFILE_IMAGE = 'nerdherfers-profile-v2.jpg';

const getUrlId = (link: string) => new URL(link).pathname.replace(/\/$/, '').split('/').pop() || '';

export interface IFeedContext {
  feed: Episode[];
  getEpisodeBySlug: (slug: string) => Episode | undefined;
  seasons: number;
}

export const FeedContext =
  React.createContext<Partial<IFeedContext>>({});

export const FeedProvider = ({children}: PropsWithChildren) => {

    const [{
        feed = [],
        feedData = [],
        seasons = 1,
    }, dispatch] = useReducer(reducer, {});

    const { episodeMetasRef } = useRefs();

    const [metas] = useCollectionOnce(
        episodeMetasRef,
    )

    useEffect(() => {
        (async () => {
            await fetch('https://feed.podbean.com/nerdherfers/feed.xml')
                .then(res => res.text())
                .then(text => new XMLParser().parseFromString(text))
                .then(async json => {
                    const items = await json.getElementsByTagName('item');
                    dispatch({ feedData: items });
                });
        })();
    },[]);

    useEffect(() => {
        (async () => {
            const result: Episode[] = [];
            if (!feedData?.length) return result;
            const seasonsArr: number[] = [];
            for (const item of feedData) {
                const guid = await item.getElementsByTagName('guid')?.[0]?.value?.split('/').pop();
                const title = await item.getElementsByTagName('title')?.[0].value;
                let image = (await item.getElementsByTagName('itunes:image')?.[0]?.attributes?.href);
                if (!image) image = cdnImage(DEFAULT_PROFILE_IMAGE);
                const link = await item.getElementsByTagName('link')?.[0].value;
                const text = parser.parse((await item.getElementsByTagName('itunes:summary')?.[0])?.value.replace(/\n/gi, '<br/>').replace(/>$/, ''));
                const d = await item.getElementsByTagName('itunes:duration')?.[0].value;
                const season = parseInt(await item.getElementsByTagName('itunes:season')?.[0]?.value) || 0;
                if (season && !seasonsArr.includes(season)) seasonsArr.push(season)
                const episode = parseInt(await item.getElementsByTagName('itunes:episode')?.[0]?.value) || 0;
                const trackedLink = await item.getElementsByTagName('enclosure')?.[0]?.attributes?.url;
                let duration = 0;
                if (d) {
                    let [hr, min, sec] = d.split(':');
                    if (!sec) {
                        sec = min;
                        min = hr;
                        hr = 0;
                    }
                    duration = (parseInt(hr) * 60) + parseInt(min);
                }
                const dt = await item.getElementsByTagName('pubDate')?.[0].value;
                const published = dt ? DateTime.fromJSDate(new Date(dt)) : null;
                const id = getUrlId(link);
                let meta = episodeMeta.find((m: EpisodeMeta) => m.season === season && m.episode === episode);
                if (!meta) {
                    const doc = metas?.docs?.find(m => m.get('episodeSlug') === id);
                    if (doc?.exists()) 
                        meta = Object.assign({}, { ...doc.data(), id: doc.id });
                }
                result.push({
                    id,
                    guid,
                    title,
                    image,
                    link,
                    text,
                    published,
                    duration,
                    season,
                    episode,
                    trackedLink,
                    meta,
                });
            }
            dispatch({ feed: result, seasons: seasonsArr.length });
        })();
    },[feedData,metas])

    const getEpisodeBySlug = (slug: string) => feed?.find((e: Episode) => e.id === slug);

    return (
        <FeedContext.Provider value={{ feed, getEpisodeBySlug, seasons }}>
        {children}
        </FeedContext.Provider>
    );
};
